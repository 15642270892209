.App {
	text-align: center;
}

/* .landing {
	background-image: url(./images/Landing\ diet\ –\ 4.png);
} */

/* .footer {
	background-image: url(./images/Bg\ Shape\ for\ footer.png);
	background-size: cover;
} */
